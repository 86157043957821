.main-img-box {
    position: relative;
    margin-top: 75px;
    width: 100%;
    height: 30vw;
    max-height: 550px;
    min-height: 350px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.main-img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    /*filter: brightness(0.5);*/
}

.kenburns-top{-webkit-animation:kenburns-top 5s ease-out infinite alternate both;animation:kenburns-top 5s ease-out infinite alternate both}

@-webkit-keyframes kenburns-top{0%{-webkit-transform:scale(1) translateY(0);transform:scale(1) translateY(0);-webkit-transform-origin:50% 16%;transform-origin:50% 16%}100%{-webkit-transform:scale(1.25) translateY(-15px);transform:scale(1.25) translateY(-15px);-webkit-transform-origin:top;transform-origin:top}}@keyframes kenburns-top{0%{-webkit-transform:scale(1) translateY(0);transform:scale(1) translateY(0);-webkit-transform-origin:50% 16%;transform-origin:50% 16%}100%{-webkit-transform:scale(1.25) translateY(-15px);transform:scale(1.25) translateY(-15px);-webkit-transform-origin:top;transform-origin:top}}
